import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classes from './CoinRating.module.css';
import CustomSpinner from '../../shared/generics/loader/Loader';
import GenericPagination from '../../shared/generics/pagination/GenericPagination';
import GenericTable from '../../shared/generics/table/Table';
import useAxios from '../../useHooks/useAxios/useAxios';
import { CoinRatingColumns } from '../../shared/utils/coinRatingData';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import Footer from '../../shared/layouts/footer/Footer';
import useLanguage from '../../useHooks/useLanguage/useLanguage';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';

const CoinRating = () => {
  const { request } = useAxios();
  const [coinRatingData, setCoinRatingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [activeButton, setActiveButton] = useState('barchaVaqt');
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const { lang, langUpdater } = useLanguage();
  const [isMyResultOn, setIsMyResultOn] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const coinRaintingColumns = CoinRatingColumns();
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('page')) || 1;

  const fetchCoinRatingData = async () => {
    setLoading(true);
    try {
      const endpoint = activeButton === 'kunlik' ? '/today' : '';
      const { data, errorMessage } = await request(
        `/reyting${endpoint}?page=${currentPage}`
      );
      if (errorMessage) {
        throw new Error(errorMessage);
      }

      isMyResultOn ? setTotal(0) : setTotal(data?.total || 0);
      const mainData = isMyResultOn
        ? data.user
          ? [data?.user]
          : []
        : data?.data;

      const formattedData = mainData?.map((value, index) => ({
        id: value?.id,
        key: index + 1,
        number: isMyResultOn ? value?.id : index + 1,
        user_image: value?.image,
        ism: value?.name,
        fillial: lang(value?.groups[0], 'branch_name'),
        guruh: lang(value?.groups[0], 'group_name'),
        eduCoin: value?.coin,
        sana: value?.created_at || '30.07.2024',
      }));

      setCoinRatingData(formattedData || []);
    } catch (error) {
      console.error('Error fetching coin rating data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoinRatingData();
  }, [activeButton, currentPage, langUpdater, isMyResultOn]);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    navigate(`?page=1`);
  };

  const handlePageChange = (page) => {
    navigate(`?page=${page}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const fetchSearchCoinData = async () => {
    setLoading(true);

    if (searchText.length == 0) {
      setLoading(false);
    }

    try {
      const { data, errorMessage } = await request(
        `/search_reyting_student?name=${searchText}`
      );
      if (errorMessage) {
        throw new Error(errorMessage);
      }

      setTotal(data?.total || 0);
      const formattedData = data?.data?.map((value, index) => ({
        id: value?.id,
        key: index + 1,
        number: index + 1,
        user_image: value?.user_image,
        ism: value?.name,
        fillial: lang(value?.groups[0], 'branch_name'),
        guruh: lang(value?.groups[0], 'group_name'),
        eduCoin: value?.coin,
        sana: value?.created_at || '30.07.2024',
      }));

      setCoinRatingData(formattedData || []);
    } catch (error) {
      setCoinRatingData([]);
      setTotal(0);
    } finally {
      setLoading(false);
    }
  };

  const handleMyResult = () => {
    setIsMyResultOn(!isMyResultOn);
  };

  const handleSearchSubmit = () => {
    fetchSearchCoinData();
    navigate(`?page=1`);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  if (error) {
    return <div className={classes['error']}>Error: {error}</div>;
  }

  return (
    <>
      <Navbar />
      <div className={classes['main-container']}>
        <div className={classes['btn-flex-wrapper']}>
          <Link to={'/home'}>
            <button className={`${classes['btn']} ${classes['go-back-btn']}`}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M8 1L2 8L8 15' stroke='#fff' strokeWidth='2' />
              </svg>
              {t('back')}
            </button>
          </Link>
        </div>

        <div className={classes['container']}>
          <div className={classes['header-box']}>
            <h1 className={classes['title']}>{t('coin_rating_all')}</h1>
            <div className={classes['btn-flex']}>
              <button
                className={`${classes['btn']} ${
                  activeButton === 'barchaVaqt' ? classes['active'] : ''
                } ${classes['double-btn']}`}
                onClick={() => handleButtonClick('barchaVaqt')}
              >
                {t('rt_btn_all')}
              </button>
              <button
                className={`${classes['btn']} ${
                  activeButton === 'kunlik' ? classes['active'] : ''
                } ${classes['double-btn']}`}
                onClick={() => handleButtonClick('kunlik')}
              >
                {t('rt_btn_day')}
              </button>
            </div>
          </div>
          <p className={classes['descr']}>{t('rt_update')}</p>
          <div className={classes['search-flex']}>
            <input
              className={classes['search-input']}
              placeholder={t('rt_search_name')}
              type='text'
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              onClick={handleSearchSubmit}
              className={classes['search-btn']}
            >
              <Icon icon='ic:baseline-search' className={classes['icon']} />
              {t('rt_search')}
            </button>
            {isMyResultOn ? (
              <button
                onClick={handleMyResult}
                className={classes['search-btn']}
              >
                <Icon icon='akar-icons:location' className={classes['icon']} />
                {t('rt_find_all')}
              </button>
            ) : (
              <button
                onClick={handleMyResult}
                className={classes['search-btn']}
              >
                <Icon icon='akar-icons:location' className={classes['icon']} />
                {t('rt_find_me')}
              </button>
            )}
          </div>
          {loading ? (
            <div className={classes['loader-container']}>
              <CustomSpinner />
            </div>
          ) : (
            <div className={`${classes['table-container']} table-container`}>
              <GenericTable
                columns={coinRaintingColumns}
                data={coinRatingData}
                handleRowClick={(record) =>
                  navigate(`/coin-history/${record.id}`)
                }
                emptyTitle={
                  activeButton === 'kunlik'
                    ? 'empty_coin_rating'
                    : 'empty_not_exist'
                }
              />
            </div>
          )}
          <GenericPagination
            current={currentPage}
            total={total}
            pageSize={10}
            onChange={handlePageChange}
          />
        </div>
      </div>
      <Footer />
      <Copywrite />
    </>
  );
};

export default CoinRating;
