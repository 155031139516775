import React from 'react';
import { useTranslation } from 'react-i18next';
import coinImg from '../assets/icons/f.svg';
import tickIcon from '../assets/icons/rating-tick.svg';
import errorIcon from '../assets/icons/rating-error.svg';

export const CoinHistoryColumns = () => {
  const { t } = useTranslation();

  return [
    { title: '#', dataIndex: 'number', key: 'number' },
    {
      title: 'Coin',
      dataIndex: 'coin',
      key: 'coin',
      render: (text) => (
        <div className='table-coin-box-flex'>
          <img src={coinImg} alt='coin' />
          {text}
        </div>
      ),
    },
    { title: t('rt_from_whom'), dataIndex: 'kimdan', key: 'kimdan' },
    { title: t('rt_comment'), dataIndex: 'izoh', key: 'izoh' },
    {
      title: t('rt_status'),
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        text ? (
          <img src={tickIcon} alt='tick' />
        ) : (
          <img src={errorIcon} alt='error' />
        ),
    },
    { title: t('rt_date'), dataIndex: 'sana', key: 'sana' },
  ];
};
