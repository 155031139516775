import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { Radio } from 'antd';
import { Icon } from '@iconify/react';
import parse from 'html-react-parser';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Footer from '../../shared/layouts/footer/Footer';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import classes from './Quiz.module.css';
import QuizAnswer from '../quizAnswer/QuizAnswer';
import CustomSpinner from '../../shared/generics/loader/Loader';
import { useParams } from 'react-router-dom';
import useAxios from '../../useHooks/useAxios/useAxios';
import useLanguage from '../../useHooks/useLanguage/useLanguage';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const Quiz = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [quizData, setQuizData] = useState([]);
  const { testId } = useParams();
  const [loading, setLoading] = useState(true);
  const { request } = useAxios();
  const { lang } = useLanguage();
  const { t } = useTranslation();

  const [remainingTime, setRemainingTime] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const fetchData = async () => {
    setLoading(true);
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request(`/test_topics/${testId}`);

    setLoading(isLoading);

    if (errorMessage) {
      console.error('Error updating message status:', errorMessage);
    } else {
      setQuizData(data);
      setStartTime(Date.now());
      setRemainingTime(data?.time * 60);
    }
  };

  useEffect(() => {
    fetchData();
  }, [testId]);

  useEffect(() => {
    if (!loading && remainingTime <= 0) {
      handleSubmit();
    } else {
      const timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [remainingTime, loading]);

  const handleOptionChange = (e) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [currentQuestion]: e.target.value,
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestion < quizData.questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const calculateResult = async () => {
    const answers = quizData.questions.map((question, index) => ({
      question_id: question.id,
      selected_option:
        selectedAnswers[index] !== undefined
          ? quizData.questions[index]?.options[selectedAnswers[index] - 1]?.id
          : -1,
    }));

    const usedTime = parseInt((Date.now() - startTime) / 1000);

    const { errorMessage, data } = await request('/check_answers', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        test_topic_id: testId,
        time: usedTime,
        answers: answers,
      }),
    });

    if (errorMessage) {
      console.error('Error updating message status:', errorMessage);
    } else {
      setResult(data);
      setShowResult(true);
    }
  };

  const handleSubmit = () => {
    if (Object.values(selectedAnswers).length === quizData?.questions?.length) {
      calculateResult();
    } else if (remainingTime === 0) {
      calculateResult();
    } else {
      message.error({
        content: t('test_message_not_end'),
        key: 1,
        duration: 2,
      });
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const openLightbox = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsOpen(true);
  };

  if (loading) {
    return (
      <>
        <Navbar />
        <div className={classes['loader-container']}>
          <CustomSpinner />
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      {showResult ? (
        <QuizAnswer result={result} />
      ) : (
        <>
          <h1 className={`${classes['header']} gradient-txt`}>
            {lang(quizData, 'test_topic_name')}
          </h1>
          <div className={classes['container']}>
            <div className={classes['test-box']}>
              <div className={classes['qs-box']}>
                {lang(
                  quizData?.questions[currentQuestion],
                  'question_image'
                ) && (
                  <img
                    className={classes['quiz-img']}
                    src={`${BASE_URL}/storage/${lang(
                      quizData?.questions[currentQuestion],
                      'question_image'
                    )}`}
                    alt=''
                    onClick={() =>
                      openLightbox(
                        `${BASE_URL}/storage/${lang(
                          quizData?.questions[currentQuestion],
                          'question_image'
                        )}`
                      )
                    }
                  />
                )}

                {lang(quizData?.questions[currentQuestion], 'question_text')}
              </div>
              <Radio.Group
                onChange={handleOptionChange}
                value={selectedAnswers[currentQuestion]}
                className={classes['option-box']}
              >
                {quizData.questions[currentQuestion]?.options.map(
                  (option, index) => (
                    <Radio
                      key={index}
                      value={index + 1}
                      className={classes['option-text']}
                      style={{
                        display: 'block',
                        marginBottom: '10px',
                      }}
                    >
                      {lang(option, 'option_text')}
                    </Radio>
                  )
                )}
              </Radio.Group>
              <button
                className={classes['btn']}
                onClick={
                  currentQuestion < quizData?.questions?.length - 1
                    ? handleNextQuestion
                    : handleSubmit
                }
              >
                {currentQuestion < quizData?.questions?.length - 1
                  ? lang(quizData, 'test_subject_name') === 'Ingliz tili'
                    ? 'Next'
                    : t('test_btn_next')
                  : lang(quizData, 'test_subject_name') === 'Ingliz tili'
                  ? 'Submit'
                  : t('test_btn_submit')}
              </button>
            </div>
            <div className={classes['right-box']}>
              <div className={classes['timer']}>
                <Icon
                  icon='mdi:clock-outline'
                  className={classes['clock-icon']}
                />{' '}
                {t('test_time_left')}: {formatTime(remainingTime)}
              </div>
              <div className={classes['progress-box']}>
                <p className={classes['progress-text']}>
                  {parse(
                    t('test_descr', {
                      test_subject_name: lang(quizData, 'test_subject_name'),
                    })
                  )}
                </p>
                <div className={classes['progress-flex']}>
                  {quizData.questions.map((_, index) => (
                    <div
                      key={index}
                      className={classes['progress-item']}
                      style={{
                        backgroundColor:
                          index === currentQuestion
                            ? '#d42620'
                            : selectedAnswers[index] !== undefined
                            ? 'var(--primary-color)'
                            : '#333333',
                        cursor: 'pointer',
                      }}
                      onClick={() => setCurrentQuestion(index)}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {isOpen && (
            <Lightbox
              slides={[{ src: currentImage }]}
              onClose={() => setIsOpen(false)}
            />
          )}
        </>
      )}
      <Footer withoutMargin={true} />
      <Copywrite />
    </>
  );
};

export default Quiz;
