import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CompanySettingsContext } from '../context/companySettingsContext/CompanySettingsContext';

const Root = ({ children }) => {
  const { settingsData } = useContext(CompanySettingsContext);

  useEffect(() => {
    if (settingsData?.logo) {
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = settingsData.logo;
      } else {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = settingsData.logo;
        document.head.appendChild(link);
      }
    }
  }, [settingsData]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{settingsData?.title || 'EduCoin platformasi'}</title>
        <meta
          name='description'
          content={
            settingsData?.description ||
            "EduCoin platformasi ta'limda innovatsion yondashuv bo'lib, o'quv markazlar hamda xusisiy maktab o'quvchilariga bilim olish jarayonini yanada qiziqarli va rag'batlantiruvchi holga keltiradi."
          }
        />
        <meta
          name='keywords'
          content={
            settingsData?.keywords ||
            "educoin, educoin.uz, coin, edu, dasturlash, ingliz tili, tez yozish, ko'paytirish"
          }
        />
        <link rel='icon' href={settingsData?.logo} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default Root;
