import React from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../assets/icons/rating-avatar.svg';
import coinImg from '../assets/icons/f.svg';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CoinRatingColumns = () => {
  const { t } = useTranslation();

  return [
    { title: '#', dataIndex: 'number', key: 'number' },
    {
      title: t('rt_picture'),
      dataIndex: 'user_image',
      key: 'user_image',
      render: (text) =>
        text ? (
          <img
            className='typing-avatar-img'
            src={`${BASE_URL}/storage/${text}`}
            alt='img-avatar'
          />
        ) : (
          <img src={img1} alt='avatar' />
        ),
    },
    { title: t('rt_name'), dataIndex: 'ism', key: 'ism' },
    {
      title: '#eduCoin',
      dataIndex: 'eduCoin',
      key: 'eduCoin',
      render: (text) => (
        <div className='table-coin-box-flex'>
          <img src={coinImg} alt='coin' />
          {text}
        </div>
      ),
    },
    { title: t('rt_branch'), dataIndex: 'fillial', key: 'fillial' },
    { title: t('rt_group'), dataIndex: 'guruh', key: 'guruh' },
  ];
};
