import React from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../assets/icons/rating-avatar.svg';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const TypingRatingColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: t('rt_picture'),
      dataIndex: 'user_image',
      key: 'user_image',
      render: (text) =>
        text ? (
          <img
            className='typing-avatar-img'
            src={`${BASE_URL}/storage/${text}`}
            alt='img-avatar'
          />
        ) : (
          <img src={img1} alt='avatar' />
        ),
    },
    {
      title: t('rt_name'),
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Wpm',
      dataIndex: 'max_answer',
      key: 'max_answer',
      render: (text) => <span>{parseInt(text)}</span>,
    },
    {
      title: t('rt_time'),
      dataIndex: 'time',
      key: 'time',
      render: (text) => <span>{text}s</span>,
    },
    {
      title: t('rt_date'),
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];
};
