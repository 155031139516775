import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GenericTable from '../../shared/generics/table/Table';
import CustomSpinner from '../../shared/generics/loader/Loader';
import classes from './MultiplyRating.module.css';
import { MultiplyRatingColumns } from '../../shared/utils/multiplyRatingData';
import GenericPagination from '../../shared/generics/pagination/GenericPagination';
import useAxios from '../../useHooks/useAxios/useAxios';
import { useTranslation } from 'react-i18next';

const MultiplyRating = () => {
  const { request } = useAxios();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const multiplyRatingColumns = MultiplyRatingColumns();
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('page')) || 1;
  const activeButton = query.get('type') || 'barchaVaqt';

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data, errorMessage } = await request(
        `/answers${
          activeButton === 'kunlik' ? '/today' : ''
        }/type/1?page=${currentPage}`
      );
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      setTotal(data?.total || 0);
      setData(data?.data || []);
    } catch (error) {
      console.error('Error fetching multiply rating data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, activeButton]);

  const handlePageChange = (page) => {
    navigate(`?page=${page}&type=${activeButton}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleButtonClick = (buttonType) => {
    navigate(`?page=1&type=${buttonType}`);
  };

  if (error) {
    return <div className='ec-error'>Muammo: {error}</div>;
  }

  return (
    <div className={classes['main-container']}>
      <div className={classes['btn-flex-wrapper']}>
        <Link to={'/multiply'}>
          <button className={`${classes['btn']} ${classes['go-back-btn']}`}>
            <svg
              width='20'
              height='20'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M8 1L2 8L8 15' stroke='#fff' strokeWidth='2' />
            </svg>
            {t('back')}
          </button>
        </Link>
      </div>

      <div className={classes['container']}>
        <div className={classes['header-box']}>
          <h1 className={classes['title']}>{t('multiply_rating_all')}</h1>
          <div className={classes['btn-flex']}>
            <button
              className={`${classes['btn']} ${classes['double-btn']} ${
                activeButton === 'barchaVaqt' ? classes['active'] : ''
              }`}
              onClick={() => handleButtonClick('barchaVaqt')}
            >
              {t('rt_btn_all')}
            </button>
            <button
              className={`${classes['btn']} ${classes['double-btn']} ${
                activeButton === 'kunlik' ? classes['active'] : ''
              }`}
              onClick={() => handleButtonClick('kunlik')}
            >
              {t('rt_btn_day')}
            </button>
          </div>
        </div>
        <p className={classes['descr']}>{t('rt_update')}</p>
        {loading ? (
          <div className={classes['loader-container']}>
            <CustomSpinner />
          </div>
        ) : (
          <div className={`${classes['table-container']} table-container`}>
            <GenericTable
              columns={multiplyRatingColumns}
              data={data}
              handleRowClick={() => {}}
            />
          </div>
        )}
        <GenericPagination
          current={currentPage}
          total={total}
          pageSize={10}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default MultiplyRating;
